import React from "react";
import memoize from "memoize-one";
import "react-calendar-timeline/lib/Timeline.css";
import "assets/css/OptaTimeline.css";
import "assets/scss/OptaTimeline.scss";
import OptaConfig from "components/ClassWrapper/OptaConfig";
import RdvItem from "views/planning/RdvItem";
import Place from "views/planning/Place";
import {type Dict, getISODateFromISODateTime, parseMillisecondsToISOTime} from "Utils";
import VisualRdv from "views/planning/VisualRdv";
// noinspection ES6CheckImport
import ReactCalendarTimeline, {
    CustomMarker,
    DateHeader,
    SidebarHeader,
    TimelineHeaders,
    TimelineMarkers,
    TodayMarker
} from "react-calendar-timeline";
import CompleteRdv from "components/ClassWrapper/CompleteRdv";
import Sector from "components/ClassWrapper/Sector";
import MaterialType from "components/ClassWrapper/MaterialType";
import moment, {Moment, duration} from "moment";
import "moment/locale/fr";
import {getFullNameWithPrefix, parseISODateTimeToMilliseconds} from "Utils";
import containerResizeDetector from "react-calendar-timeline/lib/resize-detector/container"
import { BED, CHAIR } from "components/ClassWrapper/SeatType";
import { type UnixInterval, type DateString } from "components/ClassWrapper/TimeClasses";
import {PlanningActionConfig} from 'views/planning/PlanningActionConfig';
import { RdvMoveError, checkAndShiftDateTimeRdvs } from "views/planning/RdvMovingHandler";
import { RDV_DISPLAY_MODE } from "./Planning"

type Unit = "second" | "minute" | "hour" | "day" | "month" | "year";

type WorkingHourMarkerData = {
    timestamp: Moment,
    type: "start" | "end",
}

type TimelineWrapperProps = {
    style: { [key: String]: String },
    config: OptaConfig,
    planningActionConfig: PlanningActionConfig,
    selectedInterval: UnixInterval,
    completeRdvMap: Dict<CompleteRdv>,
    placeList: Place[],
    dictionary: {
        sector: Dict<Sector>,
        materialType: Dict<MaterialType>,
    },
    onRdvsSelect: (crdv: CompleteRdv) => void,
    onRdvsDeselect: (crdv: CompleteRdv) => void,
    onRdvsChange: (crdvs: CompleteRdv[]) => void,
    onRdvEdit: (rdvId: Number) => void,
    selectedRdvIds: Number[],
    selectedSectorIdsForRdvs: Number[],
    selectedDoctorIds: Number[],
    publicHolidays: UnixInterval[],
    onRdvsDelete: (rdvIds: Number[]) => void,
    announceWarning: (msg?: String, lock?: Boolean) => void,
    currentDate: DateString,
    rdvDisplayMode: RDV_DISPLAY_MODE,
    planningActionCancellationConfig: {
        cancelledRdvDisplayMode: Boolean,
        autoCancelSubsequentRdv: Boolean,
    },
}

/**
 * Wrapper of timeline
 */
export default class TimelineWrapper extends React.PureComponent<TimelineWrapperProps> {
    static defaultProps = {
        selectedRdvIds: [],
        publicHolidays: []
    };

    state = {
        /**
         * @type {UnixInterval}
         */
        currentInterval: {
            start: this.props.selectedInterval.start,
            end: this.props.selectedInterval.end,
        },
        /**
         * @type {UnixInterval | null}
         */
        prevSelectedInterval: null,
    };

    scrollRef = React.createRef(null)


    /**
     *
     * @type {Dict<RdvItem>}
     */
    rdvItemMap = {};

    /**
     *
     * @type {Dict<Object<number, Array<string>>>} (start; end) -> classes
     */
    timeColumnCaches = {};


    /**
     *
     * @param crdv
     * @param sectorDict
     * @return {RdvItem} take place id as group id
     */
    parseCompleteRdvToRdvItem = (crdv: CompleteRdv, sectorDict: Dict<Sector>, rdvDisplayMode: RDV_DISPLAY_MODE): RdvItem =>
    ({
        id: crdv.rdv.id,
        group: crdv.rdv.place.id,
        title: getFullNameWithPrefix(crdv.patient),
        start_time: parseISODateTimeToMilliseconds(crdv.rdv.sessionDay, crdv.rdv.session.start),
        end_time: parseISODateTimeToMilliseconds(crdv.rdv.sessionDay, crdv.rdv.session.end),
        backgroundColor: rdvDisplayMode === "BY_SECTOR" ? sectorDict[crdv.protocol.sectorId].rgbColor : crdv.rdv.doctor?.rgbColor,
        rawData: crdv,
        canMove: !crdv.rdv.realCancel,
    });

    /**
     * If there is more than one selected rdv, this will just change the hours.
     * Else, the only selected rdv can be moved to an other group.
     * @param selectedRdvId
     * @param newStartTime
     * @param newPlaceOrder
     */
    handleRdvMoving = (selectedRdvId: number, newStartTime: number, newPlaceOrder: number) => {
        if (!this.props.completeRdvMap[selectedRdvId] || this.props.completeRdvMap[selectedRdvId].sessionDay < this.props.currentDate) return;
        // Either change place or change time
        const newPlace = this.props.placeList[newPlaceOrder];
        if (newPlace.rawData.id !== this.props.completeRdvMap[selectedRdvId].rdv.place.id) {
            const crdv = this.props.completeRdvMap[selectedRdvId];
            this.props.onRdvsChange([
                {
                    ...crdv,
                    rdv: {
                        ...crdv.rdv,
                        place: newPlace.rawData,
                    }
                }
            ]);
            return;
        }
        // Only modify the time of rdv in the same date as the selected rdv by the same amount of shift
        // The rdvs of other dates will only be moved if the selected rdv is moved to other date (future or past)
        // Careful to only modify the date, not time
        try {
            const shiftedRdvs = checkAndShiftDateTimeRdvs(
                selectedRdvId,
                this.props.selectedRdvIds,
                newStartTime,
                this.props.config,
                this.props.planningActionConfig,
                this.props.publicHolidays,
                this.rdvItemMap,
                this.props.completeRdvMap
            );
            // Finally, update the planning
            this.props.onRdvsChange(shiftedRdvs);
        } catch (e) {
            if (e instanceof RdvMoveError) this.props.announceWarning(e.message);
        }
    };

    renderTimeColumn = (timeStart: number, timeEnd: number): string[] => {
        // Public holiday (global absences) are fetched async after interval, so it must not be cached
        return this.decideColumnClassesFor(timeStart, timeEnd);
        // CACHE if needing to optimize, but careful of async loading of holidays
        // if (!this.timeColumnCaches[timeStart]) this.timeColumnCaches[timeStart] = {};
        // if (!this.timeColumnCaches[timeStart][timeEnd])
        //     this.timeColumnCaches[timeStart][timeEnd] = this.decideColumnClassesFor(timeStart, timeEnd);
        // return this.timeColumnCaches[timeStart][timeEnd];
    };

    serviceHourPrecision = 24 * 12;

    /**
     *
     * @param timeStart in unix milliseconds
     * @param timeEnd in unix milliseconds
     * @return {string[]} classes to decorate
     */
    decideColumnClassesFor = (timeStart: number, timeEnd: number): string[] => {
        if (this.hasPublicHolidayForInterval(timeStart, timeEnd)) return ["public-holiday"];
        // This is always on assumptions that timeStart, timeEnd are in same day
        const isoStart = parseMillisecondsToISOTime(timeStart),
            isoEnd = parseMillisecondsToISOTime(timeEnd);
        if (this.props.config.realOpeningHour >= isoEnd 
            || this.props.config.closingHour <= isoStart) 
            return ["no-service"];
        if (this.props.config.realOpeningHour <= isoStart
            && this.props.config.closingHour >= isoEnd)
            return ["ready-service"];
        // Handle partial service
        // Divide the timeslot into 12
        const cls = ["partial-service"];
        if (this.props.config.realOpeningHour >= isoStart) {
            const realOpeningHourTimestamp = moment(timeStart).startOf("D").add(duration(this.props.config.realOpeningHour)).valueOf(),
                startingRatio = (realOpeningHourTimestamp - timeStart) * 1.0 / (timeEnd - timeStart),
                startingIndex = Math.floor(startingRatio * this.serviceHourPrecision);
            cls.push(`start-${startingIndex}`);
        } 
        if (this.props.config.closingHour <= isoEnd) {
            const closingHourTimestamp = moment(timeEnd).startOf("D").add(duration(this.props.config.closingHour)).valueOf(),
                closingRatio = 1.0 - (timeEnd - closingHourTimestamp) * 1.0 / (timeEnd - timeStart),
                startingIndex = Math.floor(closingRatio * this.serviceHourPrecision);
            cls.push(`end-${startingIndex}`);
        }
        return cls;
    };

    hasPublicHolidayForInterval(timeStart: number, timeEnd: number): boolean {
        return this.props.publicHolidays.some((absence: {start: number, end: number}) => (absence.start <= timeEnd && absence.end >= timeStart));
    }

    toggleRdvSelect = (itemId: number) => {
        if (this.props.selectedRdvIds.includes(itemId))
            this.props.onRdvsDeselect(this.props.completeRdvMap[itemId]);
        else
            this.props.onRdvsSelect(this.props.completeRdvMap[itemId]);
    };

    handleMassRdvDeselect = () => this.props.onRdvsDeselect();

    /**
     *
     * @param {Place} group
     * @return {HTMLElement}
     */
    renderPlaceGroupHeader = ({group}: {group: Place}) =>
            (
                    <div className="bg-white position-relative">
                        <div style={{
                            backgroundColor: group.backgroundColor,
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "0.5rem",
                            height: "100%",
                        }}/>
                        <span className="ml-3 h-100 bg-white font-weight-bold">
                            <i className={`text-center fas ${
                                    group.rawData.type.seatType === BED ? "fa-bed"
                                    : group.rawData.type.seatType === CHAIR ? "fa-chair"
                                    : "fa-question-circle"
                                }`} style={{width: "20px"}}/>
                            {group.title}
                        </span>
                    </div>
            );

    cleanTimeColumnCaches() {
        this.timeColumnCaches = {};
    }

    visualRdvRender = props => <VisualRdv minLabelVisibleWidth={this.props.style.minLabelVisibleWidth} onEdit={this.props.onRdvEdit} {...props}/>;

    memo_getRdvItemsFromCompleteRdvMap = memoize(
            (completeRdvMap: Dict<CompleteRdv>, rdvDisplayMode: RDV_DISPLAY_MODE, selectedSectorIdsForRdvs: number[], selectedDoctorIds: number[], cancelledRdvDisplayMode: Boolean) : Array<RdvItem> =>
                    Object.keys(completeRdvMap).length === 0 ? [] :
                            Object.values(completeRdvMap)
                                .filter(crdv => selectedSectorIdsForRdvs.includes(crdv.protocol.sectorId))
                                .filter(crdv => selectedDoctorIds.length === 0 || selectedDoctorIds.includes(crdv.rdv.doctor?.id))
                                .filter(crdv => !cancelledRdvDisplayMode ? (crdv.rdv.realCancel === null) : crdv)
                                .map(crdv => this.parseCompleteRdvToRdvItem(crdv, this.props.dictionary.sector, rdvDisplayMode))
    );

    memo_convertRdvItemListToMap = memoize(
            (rdvItemList: Array<RdvItem>): Dict<RdvItem> => {
                let _map = {};
                rdvItemList.forEach(rdvItem => _map[rdvItem.id] = rdvItem);
                return _map;
            }
    );

    onDeleteAllSelectedRdvs = () => this.props.onRdvsDelete(this.props.selectedRdvIds);

    renderDateHeaderLabel = (e: Moment[], unit: Unit, labelWidth: number ) => {
        let size = labelWidth < 50 ? "short" : (labelWidth > 50 && labelWidth < 100) ? "medium" : (labelWidth > 100 && labelWidth < 150) ? "mediumLong" : "long" 
        return e[0].format(this.dateHeaderLabelFormat[unit][size])
    }

    dateHeaderLabelFormat = {
        year: {
          long: 'YYYY',
          mediumLong: 'YYYY',
          medium: 'YYYY',
          short: 'YY'
        },
        month: {
          long: 'MMMM YYYY',
          mediumLong: 'MMMM',
          medium: 'MMMM',
          short: 'MM/YY'
        },
        week: {
          long: 'w',
          mediumLong: 'w',
          medium: 'w',
          short: 'w'
        },
        day: {
          long: 'dd D MMM',
          mediumLong: 'dd D MMM',
          medium: 'dd D',
          short: 'D'
        },
        hour: {
          long: 'dddd, LL, HH:00',
          mediumLong: 'L, HH:00',
          medium: 'HH:00',
          short: 'HH'
        },
        minute: {
          long: 'HH:mm',
          mediumLong: 'HH:mm',
          medium: 'HH:mm',
          short: 'mm',
        },
        second: {
          "long": 'mm:ss',
          mediumLong: 'mm:ss',
          medium: 'mm:ss',
          "short": 'ss'
        }  
    }

    calendarStyle = {
        height: "78vh",
        overflowY: "auto",
    }

    static getDerivedStateFromProps(props, state) {
        if (!!props.selectedInterval && props.selectedInterval !== state.prevSelectedInterval) {
            return {
                prevSelectedInterval: props.selectedInterval,
                currentInterval: props.selectedInterval,
            };
        }
        return null;
    }

    getWorkingHourMarkers = (start: Number, end: Number, config: OptaConfig): WorkingHourMarkerData[] => {
        const endMoment = moment(end);
        const currentMoment = moment(start);
        const markers : WorkingHourMarkerData[] = [];
        while (currentMoment.isSameOrBefore(endMoment)) {
            const currentDate = getISODateFromISODateTime(currentMoment.toISOString(true));
            markers.push({
                timestamp: moment(currentDate + "T" + config.realOpeningHour).valueOf(),
                type: "start"
            }, {
                timestamp: moment(currentDate + "T" + config.closingHour).valueOf(),
                type: "end"
            });
            currentMoment.add(1, 'd');
        }
        return markers;
    }

    memo_getWorkingHourMarkers = memoize(this.getWorkingHourMarkers);

    render() {
        const {start, end} = this.props.selectedInterval;
        const rdvItemList = this.memo_getRdvItemsFromCompleteRdvMap(this.props.completeRdvMap,this.props.rdvDisplayMode, this.props.selectedSectorIdsForRdvs, this.props.selectedDoctorIds, this.props.planningActionCancellationConfig.cancelledRdvDisplayMode);
        this.rdvItemMap = this.memo_convertRdvItemListToMap(rdvItemList);
        const workingHourMarkers = this.memo_getWorkingHourMarkers(start, end, this.props.config);
        return (
                <>
                    {
                        !!this.props.placeList &&
                        !!rdvItemList &&
                        <ReactCalendarTimeline
                                ref={this.scrollRef}
                                items={rdvItemList}
                                groups={this.props.placeList}
                                visibleTimeStart={start}
                                visibleTimeEnd={end}
                                dragSnap={this.props.config.timeSlotLength * 60000} // In milliseconds
                                canMove={true}
                                canChangeGroup={true}
                                stackItems={true}
                                onItemSelect={this.toggleRdvSelect}
                                onItemClick={this.toggleRdvSelect}
                                onItemDeselect={this.handleMassRdvDeselect}
                                selected={this.props.selectedRdvIds}
                                onItemMove={this.handleRdvMoving}
                                canResize={false}
                                sidebarWidth={100}
                                stickyHeader={true}
                                style={
                                    this.props.placeList.length <= 12 ? null : this.calendarStyle
                                }
                                itemRenderer={this.visualRdvRender}
                                verticalLineClassNamesForTime={this.renderTimeColumn}
                                lineHeight={35}
                                itemHeightRatio={0.8}
                                clickTolerance={10}
                                groupRenderer={this.renderPlaceGroupHeader}
                                resizeDetector={containerResizeDetector}
                        >
                            <TimelineHeaders className="sticky-top bg-primary">
                                <SidebarHeader>
                                    {({getRootProps}) => {
                                        return <div {...getRootProps()}>
                                            <div style={{
                                                width: "100%",
                                                height: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                            }}>
                                                <span className="text-white mx-1 text-truncate">Hébergements</span>
                                            </div>
                                        </div>;
                                    }}
                                </SidebarHeader>
                                <DateHeader unit="primaryHeader"/>
                                <DateHeader labelFormat={this.renderDateHeaderLabel}/>
                            </TimelineHeaders>
                            <TimelineMarkers>
                                <TodayMarker interval={this.props.config.timeSlotLength * 60000}/>
                                {
                                    workingHourMarkers.map(marker => <CustomMarker
                                        key={marker.timestamp}
                                        date={marker.timestamp}>
                                            {
                                                ({styles, date}) => <div style={styles} className={marker.type === "start" ? "working-hour-marker-start" : "working-hour-marker-end"}/>
                                            }
                                        </CustomMarker>)
                                }
                            </TimelineMarkers>
                        </ReactCalendarTimeline>
                    }
                </>
        );
    }
}