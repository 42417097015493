import React, { useState, useRef, useCallback } from "react";
import CompleteRdv from "components/ClassWrapper/CompleteRdv";
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { getFullNameWithPrefix, getFullNameWithPrefixDoctor, getLocalDateStrFromISO, printIsoTime } from "Utils";
import Staff from "components/ClassWrapper/Staff";
import UController from "components/Controller/UController";
import EntityPicker from "components/picker/EntityPicker";
import { styled } from 'styled-components';
import { type DateString } from "components/ClassWrapper/TimeClasses";
import OptaConfig from "./ClassWrapper/OptaConfig";
import moment from "moment";
import Material from "./ClassWrapper/Material";
import { BED, CHAIR } from "./ClassWrapper/SeatType";
import ProtocolPicker from "./picker/ProtocolPicker";
import Sector from "./ClassWrapper/Sector";
import type { Dict } from "Utils";

const SuggestionItem = styled('div')`
    span{
        display: block;
        &:nth-of-type(1){
            font-weight: 700;
        }
        &:nth-of-type(2){
            font-style: italic;
        }
    }
`

const getFilteredStaffs = (inputValue: String, professionInternalId: String): Promise<Staff[]> => {
    inputValue = inputValue.trim();
    if (inputValue.length === 0)
        return Promise.resolve([]);
    else {
        return UController.staff.iQuery({ page: 0, pageSize: 5 }, [`(lastName=eqnc="*${inputValue}*",firstName=eqnc="*${inputValue}*",externalId.value=eqnc="*${inputValue}*");profession.internalId==${professionInternalId}`]).then(res => res.data);
    }
};

const getFilteredDoctors = (inputValue: String): Promise<Staff[]> => getFilteredStaffs(inputValue, "DOCTOR");
const getFilteredNurses = (inputValue: String): Promise<Staff[]> => getFilteredStaffs(inputValue, "NURSE");
const renderStaffChoice = (suggestion: Staff) => (
    <SuggestionItem>
        <span>{getFullNameWithPrefix(suggestion)}</span>
    </SuggestionItem>
);
const getFilteredSeats = (inputValue: string): Promise<Material[]> => {
    inputValue = inputValue.trim();
    if (inputValue.length === 0) return Promise.resolve([]);
    return UController.material.iQuery({
        page: 0,
        pageSize: 5
    }, [
        `number=eqnc="*${inputValue}*"`,
    ]).then(res => res.data);
}
const printSeat = (seat: Material) => <span><i className={`text-center fas ${seat.type.seatType === BED ? "fa-bed"
    : seat.type.seatType === CHAIR ? "fa-chair"
        : "fa-question-circle"
    }`} /> {seat.number}</span>;
const renderSeatChoice = (seat: Material) => (
    <SuggestionItem>
        {printSeat(seat)}
    </SuggestionItem>
)

const RendezvousForm = ({ crdv, onSubmit, onClose, currentDate, config, sectorDict }: {
    crdv: CompleteRdv,
    onSubmit: (modifiedRdv: CompleteRdv, newStartTime?: number) => void,
    onClose: () => void,
    currentDate: DateString,
    config: OptaConfig,
    sectorDict: Dict<Sector>
}) => {
    /**
     * @type {[Staff, (s: Staff) => void]}
     */
    const [nurse, setNurse] = useState(crdv.rdv.nurse);
    /**
     * @type {[Staff, (s: Staff) => void]}
     */
    const [doctor, setDoctor] = useState(crdv.rdv.doctor);
    const commentRef = useRef();
    const sessionDateRef = useRef();
    const sessionStartRef = useRef();
    const [seat, setSeat] = useState(crdv.rdv.place);
    const [protocol, setProtocol] = useState(crdv.protocol);

    const handleSubmit = useCallback(() => {
        onSubmit({
            ...crdv,
            protocol,
            rdv: {
                ...crdv.rdv,
                doctor,
                nurse,
                comment: commentRef.current?.value,
                place: seat
            }
        }, (sessionDateRef.current && sessionDateRef.current.value + "" !== crdv.rdv.sessionDay) 
            || (sessionStartRef.current && printIsoTime(sessionStartRef.current.value) !== printIsoTime(crdv.rdv.session.start)) ? 
            moment((sessionDateRef.current.value ?? crdv.rdv.sessionDay) + "T" + (sessionStartRef.current.value ?? crdv.rdv.session.start)).valueOf() 
            : null);
    }, [crdv, doctor, nurse, seat, protocol, onSubmit]);

    const cancelNurse = useCallback(() => setNurse(null), [setNurse]);
    const cancelDoctor = useCallback(() => setDoctor(null), [setDoctor]);

    return (
        <Modal style={{ maxWidth: '1000px', width: '100%' }}
            isOpen={true}
            toggle={onClose}
            zIndex={1100}>
            <ModalHeader toggle={onClose} tag={"h3"}>
                Modifier le rendez-vous de {getFullNameWithPrefix(crdv.patient)} le {getLocalDateStrFromISO(crdv.rdv.sessionDay, true)}
            </ModalHeader>
            <ModalBody>
                <FormGroup key={"session"}>
                    <Row>
                        <Col md={2}>
                            <Label for="session-date">
                                <span className="d-inline-block">Date : </span>
                            </Label>
                            <Input id="session-date"
                                type="date"
                                defaultValue={crdv.rdv.sessionDay}
                                min={currentDate}
                                innerRef={sessionDateRef}
                            />
                        </Col>
                        <Col md={2}>
                            <Label for="session-start">
                                <span className="d-inline-block">Début : </span>
                            </Label>
                            <Input id="session-start"
                                type="time"
                                defaultValue={crdv.rdv.session.start.substring(0, 5)}
                                innerRef={sessionStartRef}
                            />
                        </Col>
                        <Col md={3}>
                            <Label for="place">
                                <span className="d-inline-block">Hébergement : </span>
                                <div className="d-inline-block">
                                    <span className="font-weight-bold text-primary mx-2">{printSeat(seat)}</span>
                                </div>
                            </Label>
                            <EntityPicker
                                async
                                placeholder={""}
                                onChooseValue={setSeat}
                                getFilteredSuggestions={getFilteredSeats}
                                getSuggestionValue={printSeat}
                                renderSuggestion={renderSeatChoice}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup key={"cycle"}>
                    <Row>
                        <Col md={12}>
                            <Label for="protocol" className="d-block">
                                <span>Protocole : </span>
                                <span className="font-weight-bold text-primary mx-2 text-truncate">{protocol.name}</span>
                            </Label>
                            <ProtocolPicker sectorDict={sectorDict} onChooseProtocol={setProtocol}/>
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup key={"resources-human"}>
                    <Row>
                        <Col md={6}>
                            <Label>
                                <span className="d-inline-block">Médecin : </span>
                                {
                                    !!doctor &&
                                    <div className="d-inline-block">
                                        <span className="font-weight-bold text-primary mx-2">{getFullNameWithPrefixDoctor(doctor)}</span>
                                        <Button close size="sm" onClick={cancelDoctor} />
                                    </div>
                                }
                            </Label>
                            <EntityPicker
                                async
                                placeholder={"Recherche par nom/prénom/transcodage"}
                                onChooseValue={setDoctor}
                                getFilteredSuggestions={getFilteredDoctors}
                                getSuggestionValue={getFullNameWithPrefix}
                                renderSuggestion={renderStaffChoice}
                            />
                        </Col>
                        <Col md={6}>
                            <Label>
                                <span className="d-inline-block">Infirmière : </span>
                                {
                                    !!nurse &&
                                    <div className="d-inline-block">
                                        <span className="font-weight-bold text-primary mx-2">{getFullNameWithPrefix(nurse)}</span>
                                        <Button close size="sm" onClick={cancelNurse} />
                                    </div>
                                }
                            </Label>
                            <EntityPicker
                                async
                                placeholder={"Recherche par nom/prénom/transcodage"}
                                onChooseValue={setNurse}
                                getFilteredSuggestions={getFilteredNurses}
                                getSuggestionValue={getFullNameWithPrefix}
                                renderSuggestion={renderStaffChoice}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup key={"comment"}>
                    <Label for="rdv-edit-comment">Commentaire du RDV</Label>
                    <Input id="rdv-edit-comment" type="textarea" defaultValue={crdv.rdv.comment} innerRef={commentRef} />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={onClose}>Annuler</Button>
                <Button color="success" onClick={handleSubmit}>Modifier</Button>
            </ModalFooter>
        </Modal>
    )
};

export default RendezvousForm;